/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavigationItem } from './NavigationItem';
import {
    NavigationItemFromJSON,
    NavigationItemFromJSONTyped,
    NavigationItemToJSON,
} from './NavigationItem';

/**
 * 
 * @export
 * @interface NavigationSection
 */
export interface NavigationSection {
    /**
     * 
     * @type {string}
     * @memberof NavigationSection
     */
    key?: string;
    /**
     * 
     * @type {Array<NavigationItem>}
     * @memberof NavigationSection
     */
    navigationItems?: Array<NavigationItem>;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationSection
     */
    isCollapsed?: boolean;
}

export function NavigationSectionFromJSON(json: any): NavigationSection {
    return NavigationSectionFromJSONTyped(json, false);
}

export function NavigationSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavigationSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'navigationItems': !exists(json, 'navigationItems') ? undefined : (json['navigationItems'] as Array<NavigationItem>).map(NavigationItemFromJSON),
        'isCollapsed': !exists(json, 'isCollapsed') ? undefined : json['isCollapsed'],
    };
}

export function NavigationSectionToJSON(value?: NavigationSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'navigationItems': value.navigationItems === undefined ? undefined : (value.navigationItems as Array<NavigationItem>).map(NavigationItemToJSON),
        'isCollapsed': value.isCollapsed,
    };
}

