/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicPaginationInfo } from './BasicPaginationInfo';
import {
    BasicPaginationInfoFromJSON,
    BasicPaginationInfoFromJSONTyped,
    BasicPaginationInfoToJSON,
} from './BasicPaginationInfo';
import type { WorkflowSeriesInfo } from './WorkflowSeriesInfo';
import {
    WorkflowSeriesInfoFromJSON,
    WorkflowSeriesInfoFromJSONTyped,
    WorkflowSeriesInfoToJSON,
} from './WorkflowSeriesInfo';

/**
 * 
 * @export
 * @interface GetDataMappedTargetWorkflowsResponseV1
 */
export interface GetDataMappedTargetWorkflowsResponseV1 {
    /**
     * 
     * @type {Array<WorkflowSeriesInfo>}
     * @memberof GetDataMappedTargetWorkflowsResponseV1
     */
    workflowSeriesInfos?: Array<WorkflowSeriesInfo>;
    /**
     * 
     * @type {BasicPaginationInfo}
     * @memberof GetDataMappedTargetWorkflowsResponseV1
     */
    paginationInfo?: BasicPaginationInfo;
}

export function GetDataMappedTargetWorkflowsResponseV1FromJSON(json: any): GetDataMappedTargetWorkflowsResponseV1 {
    return GetDataMappedTargetWorkflowsResponseV1FromJSONTyped(json, false);
}

export function GetDataMappedTargetWorkflowsResponseV1FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDataMappedTargetWorkflowsResponseV1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflowSeriesInfos': !exists(json, 'workflowSeriesInfos') ? undefined : (json['workflowSeriesInfos'] as Array<WorkflowSeriesInfo>).map(WorkflowSeriesInfoFromJSON),
        'paginationInfo': !exists(json, 'paginationInfo') ? undefined : BasicPaginationInfoFromJSON(json['paginationInfo']),
    };
}

export function GetDataMappedTargetWorkflowsResponseV1ToJSON(value?: GetDataMappedTargetWorkflowsResponseV1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflowSeriesInfos': value.workflowSeriesInfos === undefined ? undefined : (value.workflowSeriesInfos as Array<WorkflowSeriesInfo>).map(WorkflowSeriesInfoToJSON),
        'paginationInfo': BasicPaginationInfoToJSON(value.paginationInfo),
    };
}

