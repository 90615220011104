/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportWorkflowResponse
 */
export interface ExportWorkflowResponse {
    /**
     * 
     * @type {string}
     * @memberof ExportWorkflowResponse
     */
    token: string;
}

export function ExportWorkflowResponseFromJSON(json: any): ExportWorkflowResponse {
    return ExportWorkflowResponseFromJSONTyped(json, false);
}

export function ExportWorkflowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportWorkflowResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function ExportWorkflowResponseToJSON(value?: ExportWorkflowResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}

