/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActorType } from './ActorType';
import {
    ActorTypeFromJSON,
    ActorTypeFromJSONTyped,
    ActorTypeToJSON,
} from './ActorType';

/**
 * Replace a participant on a node or record. The actor id is a user or role id. Note that replacing a RULE-type participant is currently prohibited.
 * @export
 * @interface ReplaceParticipantRequest
 */
export interface ReplaceParticipantRequest {
    /**
     * 
     * @type {string}
     * @memberof ReplaceParticipantRequest
     */
    actorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParticipantRequest
     */
    actorIdToReplace?: string;
    /**
     * 
     * @type {ActorType}
     * @memberof ReplaceParticipantRequest
     */
    actorType?: ActorType;
    /**
     * 
     * @type {ActorType}
     * @memberof ReplaceParticipantRequest
     */
    actorTypeToReplace?: ActorType;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParticipantRequest
     */
    message?: string;
}

export function ReplaceParticipantRequestFromJSON(json: any): ReplaceParticipantRequest {
    return ReplaceParticipantRequestFromJSONTyped(json, false);
}

export function ReplaceParticipantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplaceParticipantRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actorId': !exists(json, 'actorId') ? undefined : json['actorId'],
        'actorIdToReplace': !exists(json, 'actorIdToReplace') ? undefined : json['actorIdToReplace'],
        'actorType': !exists(json, 'actorType') ? undefined : ActorTypeFromJSON(json['actorType']),
        'actorTypeToReplace': !exists(json, 'actorTypeToReplace') ? undefined : ActorTypeFromJSON(json['actorTypeToReplace']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ReplaceParticipantRequestToJSON(value?: ReplaceParticipantRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actorId': value.actorId,
        'actorIdToReplace': value.actorIdToReplace,
        'actorType': ActorTypeToJSON(value.actorType),
        'actorTypeToReplace': ActorTypeToJSON(value.actorTypeToReplace),
        'message': value.message,
    };
}

