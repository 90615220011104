/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ArchiveWorkflowResponseBody,
    ArchiveWorkflowResponseBodyFromJSON,
    ArchiveWorkflowResponseBodyToJSON,
    CopyWorkflowRequest,
    CopyWorkflowRequestFromJSON,
    CopyWorkflowRequestToJSON,
    CopyWorkflowResponse,
    CopyWorkflowResponseFromJSON,
    CopyWorkflowResponseToJSON,
    CreateWorkflowRequest,
    CreateWorkflowRequestFromJSON,
    CreateWorkflowRequestToJSON,
    CreateWorkflowResponse,
    CreateWorkflowResponseFromJSON,
    CreateWorkflowResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ExportWorkflowResponse,
    ExportWorkflowResponseFromJSON,
    ExportWorkflowResponseToJSON,
    GetLongestPathDurationResponse,
    GetLongestPathDurationResponseFromJSON,
    GetLongestPathDurationResponseToJSON,
    GetWorkflowLayoutResponse,
    GetWorkflowLayoutResponseFromJSON,
    GetWorkflowLayoutResponseToJSON,
    GetWorkflowTypesResponse,
    GetWorkflowTypesResponseFromJSON,
    GetWorkflowTypesResponseToJSON,
    GetWorkflowsResponse,
    GetWorkflowsResponseFromJSON,
    GetWorkflowsResponseToJSON,
    MultipleWorkflowResponse,
    MultipleWorkflowResponseFromJSON,
    MultipleWorkflowResponseToJSON,
    PublishWorkflowResponse,
    PublishWorkflowResponseFromJSON,
    PublishWorkflowResponseToJSON,
    ReviseWorkflowResponseBody,
    ReviseWorkflowResponseBodyFromJSON,
    ReviseWorkflowResponseBodyToJSON,
    UpdateWorkflowRequest,
    UpdateWorkflowRequestFromJSON,
    UpdateWorkflowRequestToJSON,
    UpdateWorkflowResponse,
    UpdateWorkflowResponseFromJSON,
    UpdateWorkflowResponseToJSON,
    ValidateWorkflowVodRequest,
    ValidateWorkflowVodRequestFromJSON,
    ValidateWorkflowVodRequestToJSON,
    ValidateWorkflowVodResponseBody,
    ValidateWorkflowVodResponseBodyFromJSON,
    ValidateWorkflowVodResponseBodyToJSON,
    WorkflowConfigurationPackage,
    WorkflowConfigurationPackageFromJSON,
    WorkflowConfigurationPackageToJSON,
    WorkflowConfigurationValidationResponseBody,
    WorkflowConfigurationValidationResponseBodyFromJSON,
    WorkflowConfigurationValidationResponseBodyToJSON,
    WorkflowMetadata,
    WorkflowMetadataFromJSON,
    WorkflowMetadataToJSON,
    WorkflowStatus,
    WorkflowStatusFromJSON,
    WorkflowStatusToJSON,
} from '../models';

export interface ArchiveWorkflowRequest {
    workflowId: string;
}

export interface CopyWorkflowOperationRequest {
    workflowId: string;
    copyWorkflowRequest: CopyWorkflowRequest;
}

export interface CopyWorkflowToTenantRequest {
    workflowId: string;
    oldTenantId: string;
    newTenantId: string;
}

export interface CreateWorkflowOperationRequest {
    createWorkflowRequest: CreateWorkflowRequest;
}

export interface DeleteWorkflowRequest {
    workflowId: string;
}

export interface ExportWorkflowRequest {
    workflowId: string;
}

export interface GetLaunchableWorkflowsV1Request {
    appId: string;
    pageNumber?: number;
    pageSize?: number;
}

export interface GetLongestPathDurationRequest {
    workflowId: string;
}

export interface GetPublishedWorkflowConfigurationPackageByIdRequest {
    workflowId: string;
}

export interface GetWorkflowLayoutRequest {
    workflowId: string;
}

export interface GetWorkflowMetadataByTokenRequest {
    token: string;
}

export interface GetWorkflowTypesRequest {
    appId: string;
}

export interface GetWorkflowsRequest {
    appId: string;
    name?: string;
    type?: string;
    statuses?: Array<WorkflowStatus>;
    latest?: boolean;
    userContextCheck?: boolean;
    pageNumber?: number;
    pageSize?: number;
}

export interface GetWorkflowsByIdsRequest {
    ids: Array<string>;
}

export interface GetWorkflowsBySeriesIdRequest {
    seriesId: string;
    appId: string;
    pageNumber?: number;
    pageSize?: number;
}

export interface NumberingPrefixCheckRequest {
    numberingPrefix: string;
}

export interface PublishWorkflowRequest {
    workflowId: string;
}

export interface ReviseWorkflowRequest {
    workflowId: string;
}

export interface UpdateWorkflowOperationRequest {
    workflowId: string;
    updateWorkflowRequest: UpdateWorkflowRequest;
}

export interface ValidateWorkflowRequest {
    workflowId: string;
}

export interface ValidateWorkflowVodOperationRequest {
    workflowId: string;
    validateWorkflowVodRequest: ValidateWorkflowVodRequest;
    host?: string;
}

export interface WorkflowEditInProgressRequest {
    workflowId: string;
}

/**
 * 
 */
export class WorkflowV1Api extends runtime.BaseAPI {

    /**
     * Archives the selected workflow. Moves the workflow from the \'PUBLISHED\' status to the \'ARCHIVED\' status. 
     * Archive a Workflow
     */
    async archiveWorkflowRaw(requestParameters: ArchiveWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ArchiveWorkflowResponseBody>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling archiveWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/archive`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveWorkflowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Archives the selected workflow. Moves the workflow from the \'PUBLISHED\' status to the \'ARCHIVED\' status. 
     * Archive a Workflow
     */
    async archiveWorkflow(requestParameters: ArchiveWorkflowRequest, initOverrides?: RequestInit): Promise<ArchiveWorkflowResponseBody> {
        const response = await this.archiveWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Performs a full copy of a workflow and associated nodes, sections, form elements along with all of their properties for a given workflow ID. Rules associated with Node, Section, Form Elements and Options are also copied. 
     * Copy Workflow
     */
    async copyWorkflowRaw(requestParameters: CopyWorkflowOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CopyWorkflowResponse>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling copyWorkflow.');
        }

        if (requestParameters.copyWorkflowRequest === null || requestParameters.copyWorkflowRequest === undefined) {
            throw new runtime.RequiredError('copyWorkflowRequest','Required parameter requestParameters.copyWorkflowRequest was null or undefined when calling copyWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/copy`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyWorkflowRequestToJSON(requestParameters.copyWorkflowRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CopyWorkflowResponseFromJSON(jsonValue));
    }

    /**
     * Performs a full copy of a workflow and associated nodes, sections, form elements along with all of their properties for a given workflow ID. Rules associated with Node, Section, Form Elements and Options are also copied. 
     * Copy Workflow
     */
    async copyWorkflow(requestParameters: CopyWorkflowOperationRequest, initOverrides?: RequestInit): Promise<CopyWorkflowResponse> {
        const response = await this.copyWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Performs a full copy of a workflow and associated nodes, sections, form elements along with all of their properties for a given workflow ID. Rules associated with Node, Section, Form Elements and Options are also copied.  Custom lists, tags, and users are removed. 
     * Copy Workflow to new Tenant
     */
    async copyWorkflowToTenantRaw(requestParameters: CopyWorkflowToTenantRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CopyWorkflowResponse>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling copyWorkflowToTenant.');
        }

        if (requestParameters.oldTenantId === null || requestParameters.oldTenantId === undefined) {
            throw new runtime.RequiredError('oldTenantId','Required parameter requestParameters.oldTenantId was null or undefined when calling copyWorkflowToTenant.');
        }

        if (requestParameters.newTenantId === null || requestParameters.newTenantId === undefined) {
            throw new runtime.RequiredError('newTenantId','Required parameter requestParameters.newTenantId was null or undefined when calling copyWorkflowToTenant.');
        }

        const queryParameters: any = {};

        if (requestParameters.oldTenantId !== undefined) {
            queryParameters['oldTenantId'] = requestParameters.oldTenantId;
        }

        if (requestParameters.newTenantId !== undefined) {
            queryParameters['newTenantId'] = requestParameters.newTenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/copyToTenant`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CopyWorkflowResponseFromJSON(jsonValue));
    }

    /**
     * Performs a full copy of a workflow and associated nodes, sections, form elements along with all of their properties for a given workflow ID. Rules associated with Node, Section, Form Elements and Options are also copied.  Custom lists, tags, and users are removed. 
     * Copy Workflow to new Tenant
     */
    async copyWorkflowToTenant(requestParameters: CopyWorkflowToTenantRequest, initOverrides?: RequestInit): Promise<CopyWorkflowResponse> {
        const response = await this.copyWorkflowToTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new Workflow
     * Create a Workflow
     */
    async createWorkflowRaw(requestParameters: CreateWorkflowOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateWorkflowResponse>> {
        if (requestParameters.createWorkflowRequest === null || requestParameters.createWorkflowRequest === undefined) {
            throw new runtime.RequiredError('createWorkflowRequest','Required parameter requestParameters.createWorkflowRequest was null or undefined when calling createWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkflowRequestToJSON(requestParameters.createWorkflowRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateWorkflowResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new Workflow
     * Create a Workflow
     */
    async createWorkflow(requestParameters: CreateWorkflowOperationRequest, initOverrides?: RequestInit): Promise<CreateWorkflowResponse> {
        const response = await this.createWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a Workflow Configuration by workflow ID  Must provide a workflow ID as part of the path. Only workflows in DRAFT can be deleted. 
     * Delete a DRAFT Workflow Configuration
     */
    async deleteWorkflowRaw(requestParameters: DeleteWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling deleteWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a Workflow Configuration by workflow ID  Must provide a workflow ID as part of the path. Only workflows in DRAFT can be deleted. 
     * Delete a DRAFT Workflow Configuration
     */
    async deleteWorkflow(requestParameters: DeleteWorkflowRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteWorkflowRaw(requestParameters, initOverrides);
    }

    /**
     * Exports the workflow so that it can later be imported into another tenant and environment. 
     * Export a workflow to a new tenant
     */
    async exportWorkflowRaw(requestParameters: ExportWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExportWorkflowResponse>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling exportWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/export`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportWorkflowResponseFromJSON(jsonValue));
    }

    /**
     * Exports the workflow so that it can later be imported into another tenant and environment. 
     * Export a workflow to a new tenant
     */
    async exportWorkflow(requestParameters: ExportWorkflowRequest, initOverrides?: RequestInit): Promise<ExportWorkflowResponse> {
        const response = await this.exportWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all Workflows that can be launched by the authenticated user. Valid workflows are those where the authenticated user is assigned to the first step of a Workflow. Results will exclude all workflows that cannot be directly launched, such as Action Item workflows. 
     * Get all launchable Workflows for a given \'appId\' filtered by optional query parameter(s). Deprecated in favor of v2. 
     */
    async getLaunchableWorkflowsV1Raw(requestParameters: GetLaunchableWorkflowsV1Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetWorkflowsResponse>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling getLaunchableWorkflowsV1.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId !== undefined) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflows/v1/launchable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWorkflowsResponseFromJSON(jsonValue));
    }

    /**
     * Gets all Workflows that can be launched by the authenticated user. Valid workflows are those where the authenticated user is assigned to the first step of a Workflow. Results will exclude all workflows that cannot be directly launched, such as Action Item workflows. 
     * Get all launchable Workflows for a given \'appId\' filtered by optional query parameter(s). Deprecated in favor of v2. 
     */
    async getLaunchableWorkflowsV1(requestParameters: GetLaunchableWorkflowsV1Request, initOverrides?: RequestInit): Promise<GetWorkflowsResponse> {
        const response = await this.getLaunchableWorkflowsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Determines a Workflow\'s longest duration path and returns the summed duration in hours as well as an ordered list of the node id\'s that make up the identified path
     * Get longest chronological path duration in a Workflow
     */
    async getLongestPathDurationRaw(requestParameters: GetLongestPathDurationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetLongestPathDurationResponse>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling getLongestPathDuration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/longestPathDuration`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLongestPathDurationResponseFromJSON(jsonValue));
    }

    /**
     * Determines a Workflow\'s longest duration path and returns the summed duration in hours as well as an ordered list of the node id\'s that make up the identified path
     * Get longest chronological path duration in a Workflow
     */
    async getLongestPathDuration(requestParameters: GetLongestPathDurationRequest, initOverrides?: RequestInit): Promise<GetLongestPathDurationResponse> {
        const response = await this.getLongestPathDurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets Workflow Configuration Package for the given published Workflow id. 
     * Get Published Workflow Configuration Package
     */
    async getPublishedWorkflowConfigurationPackageByIdRaw(requestParameters: GetPublishedWorkflowConfigurationPackageByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowConfigurationPackage>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling getPublishedWorkflowConfigurationPackageById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/package`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowConfigurationPackageFromJSON(jsonValue));
    }

    /**
     * Gets Workflow Configuration Package for the given published Workflow id. 
     * Get Published Workflow Configuration Package
     */
    async getPublishedWorkflowConfigurationPackageById(requestParameters: GetPublishedWorkflowConfigurationPackageByIdRequest, initOverrides?: RequestInit): Promise<WorkflowConfigurationPackage> {
        const response = await this.getPublishedWorkflowConfigurationPackageByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a Workflow by workflow ID along with all the nodes it contains and the node layout. Must provide a workflow ID as part of the path. 
     * Get a Workflow along with it\'s Nodes and NodeLayout
     */
    async getWorkflowLayoutRaw(requestParameters: GetWorkflowLayoutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetWorkflowLayoutResponse>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling getWorkflowLayout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWorkflowLayoutResponseFromJSON(jsonValue));
    }

    /**
     * Gets a Workflow by workflow ID along with all the nodes it contains and the node layout. Must provide a workflow ID as part of the path. 
     * Get a Workflow along with it\'s Nodes and NodeLayout
     */
    async getWorkflowLayout(requestParameters: GetWorkflowLayoutRequest, initOverrides?: RequestInit): Promise<GetWorkflowLayoutResponse> {
        const response = await this.getWorkflowLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Given a workflow migration token, this looks up the corresponding workflow that is being migrated and returns its metadata only. The workflow itself does not exist in this tenant yet. 
     * Get the workflow metadata based on a workflow migration token
     */
    async getWorkflowMetadataByTokenRaw(requestParameters: GetWorkflowMetadataByTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowMetadata>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getWorkflowMetadataByToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow-metadata/v1/migration-token/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowMetadataFromJSON(jsonValue));
    }

    /**
     * Given a workflow migration token, this looks up the corresponding workflow that is being migrated and returns its metadata only. The workflow itself does not exist in this tenant yet. 
     * Get the workflow metadata based on a workflow migration token
     */
    async getWorkflowMetadataByToken(requestParameters: GetWorkflowMetadataByTokenRequest, initOverrides?: RequestInit): Promise<WorkflowMetadata> {
        const response = await this.getWorkflowMetadataByTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets Workflow Types by Application.  Excludes system solution types
     * Get Workflow Types
     */
    async getWorkflowTypesRaw(requestParameters: GetWorkflowTypesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetWorkflowTypesResponse>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling getWorkflowTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId !== undefined) {
            queryParameters['appId'] = requestParameters.appId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/workflowTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWorkflowTypesResponseFromJSON(jsonValue));
    }

    /**
     * Gets Workflow Types by Application.  Excludes system solution types
     * Get Workflow Types
     */
    async getWorkflowTypes(requestParameters: GetWorkflowTypesRequest, initOverrides?: RequestInit): Promise<GetWorkflowTypesResponse> {
        const response = await this.getWorkflowTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all Workflows for a given \'appId\' filtered by optional query parameter(s). Must provide a supported appId as a query parameter. Defaults to returning the latest Workflows (latest=true). Not including a status and latest as query parameters will return the latest revisions of Workflows in all the statuses for the given  \'appId\'. 
     * Get all Workflows for a given \'appId\' filtered by optional query parameter(s) 
     */
    async getWorkflowsRaw(requestParameters: GetWorkflowsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetWorkflowsResponse>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling getWorkflows.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId !== undefined) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.latest !== undefined) {
            queryParameters['latest'] = requestParameters.latest;
        }

        if (requestParameters.userContextCheck !== undefined) {
            queryParameters['userContextCheck'] = requestParameters.userContextCheck;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflows/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWorkflowsResponseFromJSON(jsonValue));
    }

    /**
     * Gets all Workflows for a given \'appId\' filtered by optional query parameter(s). Must provide a supported appId as a query parameter. Defaults to returning the latest Workflows (latest=true). Not including a status and latest as query parameters will return the latest revisions of Workflows in all the statuses for the given  \'appId\'. 
     * Get all Workflows for a given \'appId\' filtered by optional query parameter(s) 
     */
    async getWorkflows(requestParameters: GetWorkflowsRequest, initOverrides?: RequestInit): Promise<GetWorkflowsResponse> {
        const response = await this.getWorkflowsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets Workflows for the given Workflow IDs 
     * Get Workflows by specified Workflow IDs
     */
    async getWorkflowsByIdsRaw(requestParameters: GetWorkflowsByIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MultipleWorkflowResponse>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getWorkflowsByIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/workflows`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultipleWorkflowResponseFromJSON(jsonValue));
    }

    /**
     * Gets Workflows for the given Workflow IDs 
     * Get Workflows by specified Workflow IDs
     */
    async getWorkflowsByIds(requestParameters: GetWorkflowsByIdsRequest, initOverrides?: RequestInit): Promise<MultipleWorkflowResponse> {
        const response = await this.getWorkflowsByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all Workflows for a given \'seriesId\' in a paginated result. 
     * Get all Workflows for a given \'seriesId\' 
     */
    async getWorkflowsBySeriesIdRaw(requestParameters: GetWorkflowsBySeriesIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetWorkflowsResponse>> {
        if (requestParameters.seriesId === null || requestParameters.seriesId === undefined) {
            throw new runtime.RequiredError('seriesId','Required parameter requestParameters.seriesId was null or undefined when calling getWorkflowsBySeriesId.');
        }

        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling getWorkflowsBySeriesId.');
        }

        const queryParameters: any = {};

        if (requestParameters.appId !== undefined) {
            queryParameters['appId'] = requestParameters.appId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflows/v1/{seriesId}`.replace(`{${"seriesId"}}`, encodeURIComponent(String(requestParameters.seriesId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWorkflowsResponseFromJSON(jsonValue));
    }

    /**
     * Gets all Workflows for a given \'seriesId\' in a paginated result. 
     * Get all Workflows for a given \'seriesId\' 
     */
    async getWorkflowsBySeriesId(requestParameters: GetWorkflowsBySeriesIdRequest, initOverrides?: RequestInit): Promise<GetWorkflowsResponse> {
        const response = await this.getWorkflowsBySeriesIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks if the numbering prefix given is unique across tenant
     * Check if Numbering Prefix is Unique
     */
    async numberingPrefixCheckRaw(requestParameters: NumberingPrefixCheckRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.numberingPrefix === null || requestParameters.numberingPrefix === undefined) {
            throw new runtime.RequiredError('numberingPrefix','Required parameter requestParameters.numberingPrefix was null or undefined when calling numberingPrefixCheck.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/numberingPrefixCheck/{numberingPrefix}`.replace(`{${"numberingPrefix"}}`, encodeURIComponent(String(requestParameters.numberingPrefix))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Checks if the numbering prefix given is unique across tenant
     * Check if Numbering Prefix is Unique
     */
    async numberingPrefixCheck(requestParameters: NumberingPrefixCheckRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.numberingPrefixCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Performs Workflow validation and then publishes the Workflow if there were no validation errors If there is a previous revision in the Published status, it will be moved to Archived status. Validation will check Workflow and Form data for valid configuration values.  If any required configuration values are not met, the request will fail. 
     * Publish a Workflow
     */
    async publishWorkflowRaw(requestParameters: PublishWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PublishWorkflowResponse>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling publishWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/publish`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublishWorkflowResponseFromJSON(jsonValue));
    }

    /**
     * Performs Workflow validation and then publishes the Workflow if there were no validation errors If there is a previous revision in the Published status, it will be moved to Archived status. Validation will check Workflow and Form data for valid configuration values.  If any required configuration values are not met, the request will fail. 
     * Publish a Workflow
     */
    async publishWorkflow(requestParameters: PublishWorkflowRequest, initOverrides?: RequestInit): Promise<PublishWorkflowResponse> {
        const response = await this.publishWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new revision of the targeted Workflow. Copies all Nodes, Forms, Sections, Form Elements, and Rules associated with the Workflow and increments the revision number associated with the workflow.  Response will include the new revision of the revised Workflow 
     * Revise a Workflow
     */
    async reviseWorkflowRaw(requestParameters: ReviseWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReviseWorkflowResponseBody>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling reviseWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/revise`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviseWorkflowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates a new revision of the targeted Workflow. Copies all Nodes, Forms, Sections, Form Elements, and Rules associated with the Workflow and increments the revision number associated with the workflow.  Response will include the new revision of the revised Workflow 
     * Revise a Workflow
     */
    async reviseWorkflow(requestParameters: ReviseWorkflowRequest, initOverrides?: RequestInit): Promise<ReviseWorkflowResponseBody> {
        const response = await this.reviseWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a Workflow
     * Update a Workflow
     */
    async updateWorkflowRaw(requestParameters: UpdateWorkflowOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateWorkflowResponse>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling updateWorkflow.');
        }

        if (requestParameters.updateWorkflowRequest === null || requestParameters.updateWorkflowRequest === undefined) {
            throw new runtime.RequiredError('updateWorkflowRequest','Required parameter requestParameters.updateWorkflowRequest was null or undefined when calling updateWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkflowRequestToJSON(requestParameters.updateWorkflowRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateWorkflowResponseFromJSON(jsonValue));
    }

    /**
     * Updates a Workflow
     * Update a Workflow
     */
    async updateWorkflow(requestParameters: UpdateWorkflowOperationRequest, initOverrides?: RequestInit): Promise<UpdateWorkflowResponse> {
        const response = await this.updateWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs a check against the Workflow to collect any outstanding validation errors 
     * Validate Workflow
     */
    async validateWorkflowRaw(requestParameters: ValidateWorkflowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowConfigurationValidationResponseBody>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling validateWorkflow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/validate`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowConfigurationValidationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Runs a check against the Workflow to collect any outstanding validation errors 
     * Validate Workflow
     */
    async validateWorkflow(requestParameters: ValidateWorkflowRequest, initOverrides?: RequestInit): Promise<WorkflowConfigurationValidationResponseBody> {
        const response = await this.validateWorkflowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs pre-publish validations and makes the S2S call to start VOD validation on the Workflow
     * Runs VOD validation on the Workflow
     */
    async validateWorkflowVodRaw(requestParameters: ValidateWorkflowVodOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ValidateWorkflowVodResponseBody>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling validateWorkflowVod.');
        }

        if (requestParameters.validateWorkflowVodRequest === null || requestParameters.validateWorkflowVodRequest === undefined) {
            throw new runtime.RequiredError('validateWorkflowVodRequest','Required parameter requestParameters.validateWorkflowVodRequest was null or undefined when calling validateWorkflowVod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.host !== undefined && requestParameters.host !== null) {
            headerParameters['host'] = String(requestParameters.host);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/validate-vod`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateWorkflowVodRequestToJSON(requestParameters.validateWorkflowVodRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateWorkflowVodResponseBodyFromJSON(jsonValue));
    }

    /**
     * Runs pre-publish validations and makes the S2S call to start VOD validation on the Workflow
     * Runs VOD validation on the Workflow
     */
    async validateWorkflowVod(requestParameters: ValidateWorkflowVodOperationRequest, initOverrides?: RequestInit): Promise<ValidateWorkflowVodResponseBody> {
        const response = await this.validateWorkflowVodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This hook informs record-config that the given workflow will be edited imminently.  It should be called by the frontend prior to fetching workflow configuration information in preparation for displaying the workflow editor.  Initially this will support record-config automatically adjusting site-based permission rule conditions if the tenant has site restrictions enabled, but could be expanded in the future to trigger other actions. 
     * Notification from the frontend that a user is beginning to edit the given draft workflow.
     */
    async workflowEditInProgressRaw(requestParameters: WorkflowEditInProgressRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling workflowEditInProgress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/action/edit_in_progress`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This hook informs record-config that the given workflow will be edited imminently.  It should be called by the frontend prior to fetching workflow configuration information in preparation for displaying the workflow editor.  Initially this will support record-config automatically adjusting site-based permission rule conditions if the tenant has site restrictions enabled, but could be expanded in the future to trigger other actions. 
     * Notification from the frontend that a user is beginning to edit the given draft workflow.
     */
    async workflowEditInProgress(requestParameters: WorkflowEditInProgressRequest, initOverrides?: RequestInit): Promise<void> {
        await this.workflowEditInProgressRaw(requestParameters, initOverrides);
    }

}
