/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DurationUnit } from './DurationUnit';
import {
    DurationUnitFromJSON,
    DurationUnitFromJSONTyped,
    DurationUnitToJSON,
} from './DurationUnit';

/**
 * 
 * @export
 * @interface CreateWorkflowRequest
 */
export interface CreateWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    appId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    numberingPrefix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkflowRequest
     */
    includeNumberingYearInitiated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkflowRequest
     */
    resetNumberingAfterYearEnd?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkflowRequest
     */
    startNumberingAt?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkflowRequest
     */
    duration?: number;
    /**
     * 
     * @type {DurationUnit}
     * @memberof CreateWorkflowRequest
     */
    durationUnit?: DurationUnit;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkflowRequest
     */
    durationInBusinessDays?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateWorkflowRequest
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateWorkflowRequest
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateWorkflowRequest
     */
    sites?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkflowRequest
     */
    allSites?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkflowRequest
     */
    requireValidation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    validationJustification?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    migrationToken?: string;
}

export function CreateWorkflowRequestFromJSON(json: any): CreateWorkflowRequest {
    return CreateWorkflowRequestFromJSONTyped(json, false);
}

export function CreateWorkflowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWorkflowRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appId': json['appId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'numberingPrefix': !exists(json, 'numberingPrefix') ? undefined : json['numberingPrefix'],
        'includeNumberingYearInitiated': !exists(json, 'includeNumberingYearInitiated') ? undefined : json['includeNumberingYearInitiated'],
        'resetNumberingAfterYearEnd': !exists(json, 'resetNumberingAfterYearEnd') ? undefined : json['resetNumberingAfterYearEnd'],
        'startNumberingAt': !exists(json, 'startNumberingAt') ? undefined : json['startNumberingAt'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : DurationUnitFromJSON(json['durationUnit']),
        'durationInBusinessDays': !exists(json, 'durationInBusinessDays') ? undefined : json['durationInBusinessDays'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'groupIds': !exists(json, 'groupIds') ? undefined : (json['groupIds'] as Array<string>),
        'sites': !exists(json, 'sites') ? undefined : (json['sites'] as Array<string>),
        'allSites': !exists(json, 'allSites') ? undefined : json['allSites'],
        'requireValidation': !exists(json, 'requireValidation') ? undefined : json['requireValidation'],
        'validationJustification': !exists(json, 'validationJustification') ? undefined : json['validationJustification'],
        'migrationToken': !exists(json, 'migrationToken') ? undefined : json['migrationToken'],
    };
}

export function CreateWorkflowRequestToJSON(value?: CreateWorkflowRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appId': value.appId,
        'name': value.name,
        'description': value.description,
        'type': value.type,
        'numberingPrefix': value.numberingPrefix,
        'includeNumberingYearInitiated': value.includeNumberingYearInitiated,
        'resetNumberingAfterYearEnd': value.resetNumberingAfterYearEnd,
        'startNumberingAt': value.startNumberingAt,
        'duration': value.duration,
        'durationUnit': DurationUnitToJSON(value.durationUnit),
        'durationInBusinessDays': value.durationInBusinessDays,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'groupIds': value.groupIds === undefined ? undefined : (value.groupIds as Array<string>),
        'sites': value.sites === undefined ? undefined : (value.sites as Array<string>),
        'allSites': value.allSites,
        'requireValidation': value.requireValidation,
        'validationJustification': value.validationJustification,
        'migrationToken': value.migrationToken,
    };
}

