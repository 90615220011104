/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowMetadata
 */
export interface WorkflowMetadata {
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    numberingPrefix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowMetadata
     */
    includeNumberingYearInitiated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkflowMetadata
     */
    startNumberingAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowMetadata
     */
    requireValidation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    validationJustification?: string;
}

export function WorkflowMetadataFromJSON(json: any): WorkflowMetadata {
    return WorkflowMetadataFromJSONTyped(json, false);
}

export function WorkflowMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'numberingPrefix': !exists(json, 'numberingPrefix') ? undefined : json['numberingPrefix'],
        'includeNumberingYearInitiated': !exists(json, 'includeNumberingYearInitiated') ? undefined : json['includeNumberingYearInitiated'],
        'startNumberingAt': !exists(json, 'startNumberingAt') ? undefined : json['startNumberingAt'],
        'requireValidation': !exists(json, 'requireValidation') ? undefined : json['requireValidation'],
        'validationJustification': !exists(json, 'validationJustification') ? undefined : json['validationJustification'],
    };
}

export function WorkflowMetadataToJSON(value?: WorkflowMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': value.type,
        'numberingPrefix': value.numberingPrefix,
        'includeNumberingYearInitiated': value.includeNumberingYearInitiated,
        'startNumberingAt': value.startNumberingAt,
        'requireValidation': value.requireValidation,
        'validationJustification': value.validationJustification,
    };
}

