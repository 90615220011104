/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NavigationPreferenceRequest
 */
export interface NavigationPreferenceRequest {
    /**
     * 
     * @type {string}
     * @memberof NavigationPreferenceRequest
     */
    sectionKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationPreferenceRequest
     */
    isCollapse?: boolean;
}

export function NavigationPreferenceRequestFromJSON(json: any): NavigationPreferenceRequest {
    return NavigationPreferenceRequestFromJSONTyped(json, false);
}

export function NavigationPreferenceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavigationPreferenceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionKey': !exists(json, 'sectionKey') ? undefined : json['sectionKey'],
        'isCollapse': !exists(json, 'isCollapse') ? undefined : json['isCollapse'],
    };
}

export function NavigationPreferenceRequestToJSON(value?: NavigationPreferenceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sectionKey': value.sectionKey,
        'isCollapse': value.isCollapse,
    };
}

