/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NavigationPreferenceResponse
 */
export interface NavigationPreferenceResponse {
    /**
     * 
     * @type {string}
     * @memberof NavigationPreferenceResponse
     */
    sectionKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationPreferenceResponse
     */
    isCollapse?: boolean;
}

export function NavigationPreferenceResponseFromJSON(json: any): NavigationPreferenceResponse {
    return NavigationPreferenceResponseFromJSONTyped(json, false);
}

export function NavigationPreferenceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavigationPreferenceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionKey': !exists(json, 'sectionKey') ? undefined : json['sectionKey'],
        'isCollapse': !exists(json, 'isCollapse') ? undefined : json['isCollapse'],
    };
}

export function NavigationPreferenceResponseToJSON(value?: NavigationPreferenceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sectionKey': value.sectionKey,
        'isCollapse': value.isCollapse,
    };
}

